import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

import iView from 'view-design';
import 'view-design/dist/styles/iview.css';
import './assets/css/layout.css';
import { setSessionStore, getSessionStore, removeSessionStore, setLocalStore, getLocalStore, removeLocalStore } from '@/libs/storage'
import { getRequest, postRequest, deleteRequest, uploadFileRequest } from '@/libs/axios'
import { getMonthRange } from '@/libs/utils'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: 'YpQZRLx5o3cVUuFrGc7kvGqMlER5R9bS' })
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  // key: 'c3b9c830d0723dd101bca6b84ff1dd2a',
  key: 'd8754122229ad9c146b7727b8548b70a',

  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation',
    'AMap.Geocoder'
  ]
});
window._AMapSecurityConfig = {
  // securityJsCode: 'c1100e0ef15f528e61e5676d96a931b3'
  securityJsCode: 'bbb368d6c85661ba1eded78aebbb7083'

}

import { VueEditor } from 'vue2-editor';
Vue.use(VueEditor);

// 挂载全局使用的方法
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
// Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.uploadFileRequest = uploadFileRequest;
Vue.prototype.setSessionStore = setSessionStore;
Vue.prototype.getSessionStore = getSessionStore;
Vue.prototype.removeSessionStore = removeSessionStore;
Vue.prototype.setLocalStore = setLocalStore;
Vue.prototype.getLocalStore = getLocalStore;
Vue.prototype.removeLocalStore = removeLocalStore;
Vue.prototype.getMonthRange = getMonthRange;

Vue.use(iView)

import { sync } from 'vuex-router-sync'
sync(store, router)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
