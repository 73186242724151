export default [
    {
        path: '/',
        name: 'home',
        redirect: '/index',
        component: () => import('@/views/home'),
        children: [
            {
                path: '/',
                name: 'homePage',
                redirect: '/login',
            },
            {
                path: '/index',
                name: 'homePage',
                component: () => import('@/views/homePage'),
                meta: {
                    bread: ['首页'],
                    menu: ['index']
                }
            },
            {
                path: '/system/appVersionManagement',
                name: 'appVersionManagement',
                component: () => import('@/views/system/appVersionManagement'),
                meta: {
                    bread: ['系统功能', 'APP版本管理'],
                    menu: ['system', 'appVersionManagement']
                }
            },
            {
                path: '/system/appLogManagement',
                name: 'appLogManagement',
                component: () => import('@/views/system/appLogManagement'),
                meta: {
                    bread: ['系统功能', 'APP日志管理'],
                    menu: ['system', 'appLogManagement']
                }
            },
            {
                path: '/system/dictionariesManagement',
                name: 'dictionariesManagement',
                component: () => import('@/views/system/dictionariesManagement'),
                meta: {
                    bread: ['系统功能', '字典项管理'],
                    menu: ['system', 'dictionariesManagement']
                }
            },
            
            {
                path: '/marketingManagement/advertisingManagement',
                name: 'advertisingManagement',
                component: () => import('@/views/marketingManagement/advertisingManagement'),
                meta: {
                    bread: ['营销管理', '广告管理'],
                    menu: ['marketingManagement', 'advertisingManagement']
                }
            },
            {
                path: '/marketingManagement/systematicManagement',
                name: 'systematicManagement',
                component: () => import('@/views/marketingManagement/systematicManagement'),
                meta: {
                    bread: ['营销管理', '分类管理'],
                    menu: ['marketingManagement', 'systematicManagement']
                }
            },
            {
                path: '/marketingManagement/integralManagement',
                name: 'integralManagement',
                component: () => import('@/views/marketingManagement/integralManagement'),
                meta: {
                    bread: ['营销管理', '积分管理'],
                    menu: ['marketingManagement', 'integralManagement']
                }
            },
            {
                path: '/marketingManagement/couponManagement',
                name: 'couponManagement',
                component: () => import('@/views/marketingManagement/couponManagement'),
                meta: {
                    bread: ['营销管理', '优惠劵管理'],
                    menu: ['marketingManagement', 'couponManagement']
                }
            },
            {
                path: '/marketingManagement/commissionConfig',
                name: 'commissionConfig',
                component: () => import('@/views/marketingManagement/commissionConfig'),
                meta: {
                    bread: ['营销管理', '佣金配置'],
                    menu: ['marketingManagement', 'commissionConfig']
                }
            },
            {
                path: '/marketingManagement/distributionManagement',
                name: 'distributionManagement',
                component: () => import('@/views/marketingManagement/distributionManagement'),
                meta: {
                    bread: ['营销管理', '分销管理'],
                    menu: ['marketingManagement', 'distributionManagement']
                }
            },
            {
                path: '/marketingManagement/regionManagement',
                name: 'regionManagement',
                component: () => import('@/views/marketingManagement/regionManagement'),
                meta: {
                    bread: ['营销管理', '地区管理'],
                    menu: ['marketingManagement', 'regionManagement']
                }
            },
            {
                path: '/marketingManagement/pushNotification',
                name: 'pushNotification',
                component: () => import('@/views/marketingManagement/pushNotification'),
                meta: {
                    bread: ['营销管理', '消息推送'],
                    menu: ['marketingManagement', 'pushNotification']
                }
            },
            {
                path: '/marketingManagement/privacyPolicy',
                name: 'privacyPolicy',
                component: () => import('@/views/marketingManagement/privacyPolicy'),
                meta: {
                    bread: ['营销管理', '隐私协议'],
                    menu: ['marketingManagement', 'privacyPolicy']
                }
            },
            {
                path: '/memberCenter/memberManagement',
                name: 'memberManagement',
                component: () => import('@/views/memberCenter/memberManagement'),
                meta: {
                    bread: ['用户中心', '用户管理'],
                    menu: ['memberCenter', 'memberManagement']
                }
            },
            {
                path: '/memberCenter/colonelApplyFor',
                name: 'colonelApplyFor',
                component: () => import('@/views/memberCenter/colonelApplyFor'),
                meta: {
                    bread: ['用户中心', '团长申请'],
                    menu: ['memberCenter', 'colonelApplyFor']
                }
            },
            {
                path: '/memberCenter/colonelDistribution',
                name: 'colonelDistribution',
                component: () => import('@/views/memberCenter/colonelDistribution'),
                meta: {
                    bread: ['用户中心', '团长分销'],
                    menu: ['memberCenter', 'colonelDistribution']
                }
            },
            {
                path: '/memberCenter/memberDescriptionManagement',
                name: 'memberDescriptionManagement',
                component: () => import('@/views/memberCenter/memberDescriptionManagement'),
                meta: {
                    bread: ['用户中心', '会员图说管理'],
                    menu: ['memberCenter', 'memberDescriptionManagement']
                }
            },
            {
                path: '/memberCenter/memberConfig',
                name: 'memberConfig',
                component: () => import('@/views/memberCenter/memberConfig'),
                meta: {
                    bread: ['用户中心', '用户配置'],
                    menu: ['memberCenter', 'memberConfig']
                }
            },
            {
                path: '/memberCenter/memberConfig2',
                name: 'memberConfig2',
                component: () => import('@/views/memberCenter/memberConfig/index2.vue'),
                meta: {
                    bread: ['用户中心', '系统配置'],
                    menu: ['memberCenter', 'memberConfig2']
                }
            },
            {
                path: '/memberCenter/memberConfig3',
                name: 'memberConfig3',
                component: () => import('@/views/memberCenter/memberConfig/index3.vue'),
                meta: {
                    bread: ['用户中心', '团配置'],
                    menu: ['memberCenter', 'memberConfig3']
                }
            },
            {
                path: '/activityCenter/merchantPublishing',
                name: 'merchantPublishing',
                component: () => import('@/views/activityCenter/merchantPublishing'),
                meta: {
                    bread: ['活动中心', '商家发布'],
                    menu: ['activityCenter', 'merchantPublishing']
                }
            },
            {
                path: '/activityCenter/colonelPublishing',
                name: 'colonelPublishing',
                component: () => import('@/views/activityCenter/colonelPublishing'),
                meta: {
                    bread: ['活动中心', '团长发布'],
                    menu: ['activityCenter', 'colonelPublishing']
                }
            },
            {
                path: '/businessCenter/businessManagement',
                name: 'businessManagement',
                component: () => import('@/views/businessCenter/businessManagement'),
                meta: {
                    bread: ['商家中心', '商家管理'],
                    menu: ['businessCenter', 'businessManagement']
                }
            },
            {
                path: '/businessCenter/storeUserManagement',
                name: 'storeUserManagement',
                component: () => import('@/views/businessCenter/storeUserManagement'),
                meta: {
                    bread: ['商家中心', '商家app用户管理'],
                    menu: ['businessCenter', 'storeUserManagement']
                }
            },
            {
                path: '/businessCenter/commodityManagement',
                name: 'commodityManagement',
                component: () => import('@/views/businessCenter/commodityManagement'),
                meta: {
                    bread: ['商家中心', '商品管理'],
                    menu: ['businessCenter', 'commodityManagement']
                }
            },
            {
                path: '/businessCenter/comboManagement',
                name: 'comboManagement',
                component: () => import('@/views/businessCenter/comboManagement'),
                meta: {
                    bread: ['商家中心', '套餐管理'],
                    menu: ['businessCenter', 'comboManagement']
                }
            },
            {
                path: '/colonelCenter/colonelManagement',
                name: 'colonelManagement',
                component: () => import('@/views/colonelCenter/colonelManagement'),
                meta: {
                    bread: ['团长中心', '团长管理'],
                    menu: ['colonelCenter', 'colonelManagement']
                }
            },
            {
                path: '/colonelCenter/colonelApplyForManagement',
                name: 'colonelApplyForManagement',
                component: () => import('@/views/colonelCenter/colonelApplyForManagement'),
                meta: {
                    bread: ['团长中心', '团长申请管理'],
                    menu: ['colonelCenter', 'colonelApplyForManagement']
                }
            },
            
            {
                path: '/groupBuyingCenter/groupBuyingManagement',
                name: 'groupBuyingManagement',
                component: () => import('@/views/groupBuyingCenter/groupBuyingManagement'),
                meta: {
                    bread: ['团购中心', '团购管理'],
                    menu: ['groupBuyingCenter', 'groupBuyingManagement']
                }
            },
            {
                path: '/groupBuyingCenter/groupBuyingEvaluateManagement',
                name: 'groupBuyingEvaluateManagement',
                component: () => import('@/views/groupBuyingCenter/groupBuyingEvaluateManagement'),
                meta: {
                    bread: ['团购中心', '团购评价管理'],
                    menu: ['groupBuyingCenter', 'groupBuyingEvaluateManagement']
                }
            },
            {
                path: '/orderCenter/orderManagement',
                name: 'orderManagement',
                component: () => import('@/views/orderCenter/orderManagement'),
                meta: {
                    bread: ['订单中心', '订单管理'],
                    menu: ['orderCenter', 'orderManagement']
                }
            },
            {
                path: '/orderCenter/withdrawManagement',
                name: 'withdrawManagement',
                component: () => import('@/views/orderCenter/withdrawManagement'),
                meta: {
                    bread: ['订单中心', '提现管理'],
                    menu: ['orderCenter', 'withdrawManagement']
                }
            },
            {
                path: '/orderCenter/runningWaterManagement',
                name: 'runningWaterManagement',
                component: () => import('@/views/orderCenter/runningWaterManagement'),
                meta: {
                    bread: ['订单中心', '流水管理'],
                    menu: ['orderCenter', 'runningWaterManagement']
                }
            },
            {
                path: '/configCenter/notificationConfig',
                name: 'notificationConfig',
                component: () => import('@/views/configCenter/notificationConfig'),
                meta: {
                    bread: ['配置中心', '通知配置'],
                    menu: ['configCenter', 'notificationConfig']
                }
            },
            {
                path: '*',
                name: 'notFound',
                component: () => import('@/views/notFound')
            }
        ]
    }
]
