/**
 * 获取某月第一天和最后一天
 */
export const getMonthRange = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // 月份是从 0 开始的，所以要加 1
    var startDate = year + '-' + month + '-01';
    var endDate = year + '-' + month + '-' + new Date(year, month, 0).getDate();
    return { startDate, endDate };
}