
const user = {
  state: {
    userInfo: {},
    navs: [],
  },
  actions: {
    //根据用户名登录
    //获取系统菜单
  },
  mutations: {
    SET_USER_INFO(state, data) {
      localStorage.setItem("userInfo", JSON.stringify(data))
      state.userInfo = data
    },
    SET_NAVS(state, data) {
      state.navs = data
    },
  }

}
export default user
