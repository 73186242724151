import axios from 'axios';
import { getSessionStore, setSessionStore } from './storage';
import { Message } from 'view-design';
import router from '../router';

// 统一请求路径前缀
export const base = '';

// 超时设定
axios.defaults.timeout = 15000;

axios.interceptors.request.use(config => {
        return config;
    }, error => {
        console.info('请求超时')
        return Promise.resolve(error);
    }
);

// http response 拦截器
axios.interceptors.response.use(response => {
    const data = response.data;
    // 根据返回的code值来做不同的处理(和后端约定)
    if (data.code == 20003 || data.code == 100444 || data.code == 100445 ) {
        // 未登录 清除已登录状态
        setSessionStore('accessToken', '');
        Message.error({
            showClose: true,
            message: '未登录或登录超时',
            type: 'error'
        });
        setTimeout(function() {
            router.replace({path: '/login'})
        }, 1500);
    }
    if(!data.success){
        Message.error(data.message||data.error)
    }
    return data;
}, (err) => {
    // 返回状态码不为200时候的错误处理
    // Message.error({
    //     showClose: true,
    //     message: '访问超时,服务器可能在维护',
    //     type: 'error'
    // });
    return Promise.resolve(err);
});

export const getRequest = (url, params) => {
    let accessToken = getSessionStore('accessToken');
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params,
        headers: {
            'X-Token': accessToken
        }
    });
};

export const postRequest = (url, params) => {
    let accessToken = getSessionStore("accessToken");
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function(data) {
            data = JSON.stringify(data)
            return data
        }],
        headers: {
            'Content-Type': 'application/json',
            'X-Token': accessToken
        }
    });
};

export const deleteRequest = (url, id) => {
    let accessToken = getSessionStore('accessToken');
    return axios({
        method: 'delete',
        url: `${base}${url}`+'/'+id,
        // params: params,
        transformRequest: [function(data) {
            data = JSON.stringify(data)
            return data
        }],
        headers: {
            'X-Token': accessToken
        }
    });
};

export const uploadFileRequest = (url, params) => {
    let accessToken = getSessionStore('accessToken');
    return axios({
        method: 'post',
        url: `${base}${url}`,
        params: params,
        headers: {
            'X-Token': accessToken
        }
    });
};
