import Vue from 'vue'
import Router from 'vue-router'
// import Store from '../store'
// const md5 = require('js-md5');



Vue.use(Router)

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

let routers = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  }]
const routersContext = require.context('./', true, /index\.js$/)

routersContext.keys().forEach(router => {
  if (router.startsWith('./index.js')) {
    return
  }
  const routerMoudle = routersContext(router)
  routers = [...routers, ...(routerMoudle.default || routerMoudle)]
})

const vueRouter = new Router({
  //   mode: 'hash',
  mode: 'history',
  // base: '/',
  routes: routers
})

export default vueRouter;
